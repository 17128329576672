import React from 'react';
import BackSchool from './img/Banners/back-to-school.jpg';
/*import HomeSlider from '../components/Home-Carousel/Carousel';*/
function Welcome() {
  return (
    <div className='welcome-container'>
          <div className='welcome-col-left'>
         <h2>മലയാള പഠനകേന്ദ്രം - പെർത്ത്  </h2>
            <p>പെർത്തിലെ കുടിയേറ്റ മലയാളി സമൂഹത്തിലെ പുതിയ തലമുറയിലേക്ക് നമ്മുടെ മാതൃഭാഷയെ കൈമോശംവരാതെ കാത്തു
            സംരക്ഷിക്കുന്നതിനായി 2022 - ൽ പെർത്ത് കേന്ദ്രമാക്കി പ്രവർത്തനമാരംഭിച്ചതാണ് മലയാള ഭാഷാ വിദ്യാലയം (School of Malayalam Language
            Inc.)<br /><br/>മറ്റൊരു രാജ്യത്തിന്റെ ഭാഷയും സംസ്കാരവും സ്വായത്വമാക്കുന്നതിനൊപ്പം നമ്മുടെ മാതൃഭാഷയെയും കൈമോശം വരാതെ
            കാത്തുസൂക്ഷിക്കുന്നതിനും, നിലനിർത്തുന്നതിനും, മലയാളഭാഷയെ  ഓസ്‌ട്രേലിയൻ പാഠ്യപദ്ധതിയിൽ ഉൾപ്പെടുത്തുന്നതിനുമുള്ള
            പരിശ്രമങ്ങളാണ് നടന്നുവരുന്നത്.</p><p className="mal-more"><a href='./about'>തുടർന്ന് വായിക്കുക...</a></p></div>
          <div className='welcome-col-right'>
            <div className='welcome-4_col col1'><h1>Enrolment</h1>
            <h5>Online enrolment form</h5>
              <div className='line_bt'><a href="https://docs.google.com/forms/d/e/1FAIpQLSd1qXQDMDsN4uQkmQ6TToJLoRQqbY274J-_lnCFcWaEK0rtDg/viewform?fbclid=IwZXh0bgNhZW0BMQABHaIspbRFn35rsSkfcaaBha2QvRAWNEt9p-Ltg7HbQQTnP6We0UAnVFxM3A_aem_AXP-g6yuAV8Pi8M4bKXVD6TaIy8uOnxinjPjVb0E815I2AYQcccWUiBzUz5ChaRmDCo" target='_blank'>Find more</a></div></div>
            
            <div className='welcome-4_col col4'><img src={BackSchool} alt="SML Perth" />
            </div>
          </div>
        </div>
  )
}

export default Welcome
