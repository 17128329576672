import React from 'react';
import { Autoplay, Pagination, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../components/Models/Styles/Slider.css';



import gal1 from '../components/img/Gallery/sml-001.webp';
import gal2 from '../components/img/Gallery/sml-002.webp';
import gal3 from '../components/img/Gallery/sml-003.webp';
import gal4 from '../components/img/Gallery/sml-004.webp';
import gal5 from '../components/img/Gallery/sml-005.webp';
import gal6 from '../components/img/Gallery/sml-006.webp';
import gal7 from '../components/img/Gallery/sml-007.webp';
import gal8 from '../components/img/Gallery/sml-008.webp';
import gal9 from '../components/img/Gallery/sml-009.webp';
import gal10 from '../components/img/Gallery/sml-010.webp';
import gal11 from '../components/img/Gallery/sml-011.webp';
import gal12 from '../components/img/Gallery/sml-012.webp';
import gal13 from '../components/img/Gallery/sml-013.webp';
import gal14 from '../components/img/Gallery/sml-014.webp';
import gal15 from '../components/img/Gallery/sml-015.webp';
import gal16 from '../components/img/Gallery/sml-016.webp';
import gal17 from '../components/img/Gallery/sml-017.webp';
import gal18 from '../components/img/Gallery/sml-018.webp';
import gal19 from '../components/img/Gallery/sml-019.webp';
import gal20 from '../components/img/Gallery/sml-020.webp';
import gal21 from '../components/img/Gallery/sml-021.webp';
import gal22 from '../components/img/Gallery/sml-022.webp';
import gal23 from '../components/img/Gallery/sml-023.webp';
import gal24 from '../components/img/Gallery/sml-024.webp';
import gal25 from '../components/img/Gallery/sml-025.webp';
import gal26 from '../components/img/Gallery/sml-026.webp';
import gal27 from '../components/img/Gallery/sml-027.webp';
import gal28 from '../components/img/Gallery/sml-028.webp';
import gal29 from '../components/img/Gallery/sml-029.webp';
import gal30 from '../components/img/Gallery/sml-030.webp';
import gal31 from '../components/img/Gallery/sml-031.webp';
import gal32 from '../components/img/Gallery/sml-032.webp';
import gal33 from '../components/img/Gallery/sml-033.webp';
import gal34 from '../components/img/Gallery/sml-034.webp';
import gal35 from '../components/img/Gallery/sml-035.webp';
import gal36 from '../components/img/Gallery/sml-036.webp';
import gal37 from '../components/img/Gallery/sml-037.webp';
import gal38 from '../components/img/Gallery/sml-038.webp';
import gal39 from '../components/img/Gallery/sml-039.webp';
import gal40 from '../components/img/Gallery/sml-040.webp';
import gal41 from '../components/img/Gallery/sml-041.webp';
import gal42 from '../components/img/Gallery/sml-042.webp';
import gal43 from '../components/img/Gallery/sml-043.webp';
import gal44 from '../components/img/Gallery/sml-044.webp';
import gal45 from '../components/img/Gallery/sml-045.webp';
import gal46 from '../components/img/Gallery/sml-046.webp';
import gal47 from '../components/img/Gallery/sml-047.webp';
import gal48 from '../components/img/Gallery/sml-048.webp';
import gal49 from '../components/img/Gallery/sml-049.webp';
import gal50 from '../components/img/Gallery/sml-050.webp';
import gal51 from '../components/img/Gallery/sml-051.webp';
import gal52 from '../components/img/Gallery/sml-052.webp';
import gal53 from '../components/img/Gallery/sml-053.webp';
import gal54 from '../components/img/Gallery/sml-054.webp';
import gal55 from '../components/img/Gallery/sml-055.webp';
import gal56 from '../components/img/Gallery/sml-056.webp';
import gal57 from '../components/img/Gallery/sml-057.webp';
import gal58 from '../components/img/Gallery/sml-058.webp';
import gal59 from '../components/img/Gallery/sml-059.webp';
import gal60 from '../components/img/Gallery/sml-060.webp';
import gal61 from '../components/img/Gallery/sml-061.webp';
import gal62 from '../components/img/Gallery/sml-062.webp';
import gal63 from '../components/img/Gallery/sml-063.webp';
import gal64 from '../components/img/Gallery/sml-064.webp';
import gal65 from '../components/img/Gallery/sml-065.webp';
import gal66 from '../components/img/Gallery/sml-066.webp';
import gal67 from '../components/img/Gallery/sml-067.webp';
import gal68 from '../components/img/Gallery/sml-068.webp';
import gal69 from '../components/img/Gallery/sml-069.webp';
import gal70 from '../components/img/Gallery/sml-070.webp';
import gal71 from '../components/img/Gallery/sml-071.webp';
import gal72 from '../components/img/Gallery/sml-072.webp';

function GallerySlider() {
  return (
    <Swiper
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        "delay": 4500,
        "disableOnInteraction": false
      }}



      navigation
      /*pagination={{ clickable: false }}
      scrollbar={{ draggable: true }}*/




      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}



    >
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal1} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal2} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal3} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal4} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal5} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal6} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal7} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal8} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal9} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal10} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal11} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal12} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal13} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal14} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal15} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal16} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal17} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal18} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal19} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal20} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal21} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal22} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal23} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal24} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal25} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal26} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal27} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal28} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal29} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal30} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal31} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal32} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal33} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal34} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal35} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal36} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal37} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal38} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal39} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal40} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal41} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal42} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal43} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal44} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal45} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal46} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal47} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal48} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal49} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal50} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal51} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal52} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal53} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal54} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal55} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal56} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal57} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal58} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal59} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal60} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal61} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal62} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal63} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal64} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal65} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal66} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal67} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal68} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal69} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal70} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal71} className='slide' alt="slider" /></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps-gal'><div className='slide-step_r1'><img src={gal72} className='slide' alt="slider" /></div></div></SwiperSlide>
    </Swiper>
  )
}

export default GallerySlider;