
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const ModalPopup4 = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <div>
            <button onClick={onOpenModal}>Read More...</button>
            <Modal open={open} onClose={onCloseModal} center>
                <h2>Year-2: മന്ദാരം. </h2>
                <p>(രണ്ടാം ക്ലാസിലെ കുട്ടികളുടെ പഠനസഹായി)
പെർത്തിലെ മലയാള ഭാഷാസ്‌കൂൾ നമ്മുടെ കുട്ടികളുടെ സാമൂഹ്യ സാഹചര്യങ്ങൾ മനസ്സിലാക്കി, അവരുടെ മാതൃഭാഷാ പഠനം
സുഗമമാക്കുന്നതായി കഴിഞ്ഞ രണ്ടുവർഷമായി കുട്ടികളുമായുള്ള നിരന്തര സമ്പർക്കവും, സാമീപ്യവും ഉൾക്കൊണ്ടുകൊണ്ട് ടീച്ചർമാരായ
സന്ധ്യ സുബിൻ, ഭാഗ്യ ശ്രീകാന്ത് എന്നിവർ ചേർന്ന് തയ്യാറാക്കിയ കൈപ്പുസ്തകത്തിന്റെ പുറംചട്ടയാണ് ഇവിടെച്ചേർക്കുന്നത്.</p>
            </Modal>
        </div>
    );
};

export default ModalPopup4;


