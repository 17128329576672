import React from 'react'
import './Style.css';
import './Media-styles.css';

import Header from './Header';
import Footer from './Footer';
import TeamBanner from './img/Banners/gallery-banner-desk.jpg';
import TeamBannerMob from './img/Banners/gallery-banner-mob.jpg';

import TeamSML from './SML-Team';

function School() {


    return (

        <>
            <Header />

            <div className='inner_container'>
                <div className='header-banner'><img src={TeamBanner} alt="SML - Perth" /></div>
                <div className='header-banner-mob'><img src={TeamBannerMob} alt="SML - Perth" /></div>
                <div className='inner-body-container'>
                    <div className='inner_body'><h1>School of Malayalam - Perth </h1>
                        <h3>Our Team  </h3>
                        <TeamSML />
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )


}

export default School
