import { Component } from 'react';
import './Navigation.css';
import './Media-styles.css';

class Navigation extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })

  }

  render() {
    return (

      <div>
        <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"}>
          <li className='desk-menu'><a className="active" href="./">Home</a></li>
          <li className='desk-menu'><a href="./about">About SML</a></li>
          <li className='desk-menu'><a href='./curriculum'>Curriculum</a></li>
          <li className='desk-menu'><a href='./Enrolment'>Enrolment</a></li>
          <li className='desk-menu'><a href="./gallery">Gallery</a></li>
          <li className='desk-menu'><a href="./sml">News</a></li>
          <li className='desk-menu'><a href="./sml">Login</a></li>
     


        </ul>
        <div id="mobile" onClick={this.handleClick}>

          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </div>
    )
  }
}



export default Navigation