import React from 'react'
import './Style.css';
import './Staff.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import AboutBanner from './img/Banners/abount-banner-desk.jpg';
import AboutBannerMob from './img/Banners/abount-banner-mob.jpg';

import Mission from './img/Banners/smp-logo.png';
import Team from './SML-Team';


function About() {
    return (
        <>
            <Header />
        
        <div className='inner_container'>
            <div className='header-banner'><img src={AboutBanner} alt="test" /></div>
            <div className='header-banner-mob'><img src={AboutBannerMob} alt="test" /></div>
            <div className='inner-body-container'>
                <div className='inner_body'><h1>About us</h1>
                    <h3>School of Malayalam - Perth </h3>
                    
                    <p>കിന്റർ ഗാർഡൻ മുതൽ ആറാം  ക്ലാസുവരെ 8 ക്ലാസുകളായും 7 -ാം ക്ലാസ്സു മുതൽ മുകളിലോട്ടുള്ള കുട്ടികൾക്കായി ഒരു ക്ലാസ്സുമാണ് 
                    ക്രമീകരിച്ചിരിക്കുന്നത്. സാധാരണ സ്‌കൂൾ ടീമുകളിലെ എല്ലാ ശനിയാഴ്ചകളിലും ഉച്ചകഴിഞ്ഞ് 3 മണി മുതൽ 5 മണി വരെ പിയാറാ
                    വാട്ടേഴ്‌സിലെ ആസ്പിരി പ്രൈമറി സ്‌കൂളിലാണ് ക്ളാസുകൾ നടന്നുവരുന്നത്. ഓസ്‌ട്രേലിയൻ സിലബസുമായി ഇഴ ചേർന്ന് നിന്നുകൊണ്ട്
                    ഈ രാജ്യത്ത് ജനിച്ചു വളരുന്ന കുട്ടികൾക്ക് ഹൃദ്യസ്‌ഥമാകുന്ന തരത്തിൽ നാം സ്വന്തമായി തയ്യാറാക്കിയ പാഠ്യപദ്ധതിയാണ്
                    പിന്തുടരുന്നത്.  നമ്മുടെ മാതൃഭാഷയെ സംരക്ഷിച്ചുനിർത്തുവാൻ നടത്തുന്ന ത്യാഗപൂർണ്ണമായ പ്രവർത്തങ്ങളിൽ പെർത്തിലെ മുഴുവൻ
                    മലയാളികളുടെയും സജീവ സഹകരണം പ്രതീക്ഷിക്കുന്നു.</p>

                    <p>History: In 2021 a group of passionate Malayalees came together to form &quot;Malayalam Mission Australia Ltd&quot; to fulfill this aspiration. They liaised with other
                    community groups and the public in Perth. An important development was the tie-up with Malayalam Mission Kerala which provides a certificate course to
                    teach Malayalam. Thus, a school was formed with the name &quot;Malayalam Community Language School, Perth&#39;&#39;.</p>
                    <p>The school is fulfillment of a long-time dream of West Australians of Malayalee origin who wanted an institution to pass on their mother tongue to the next
                    generation.  From a generation who are mostly multilingual in India, the Malayalee community was facing a scenario where the vast majority of our second-
                    generation immigrants are monolingual with language command only over English.</p>
                    <p>On joyous occasion for the Malayalee community in Perth, Western Australia, state’s first formal Malayalam language school inaugurated at 1:30 pm on
                    Saturday the 23rd of October 2021 in Piara Waters. The school has entered into an agreement with Aspiri Primary School, Piara Waters to use its facilities
                    on weekends for the school. During the initial phase, the school will run between 3:00 to 5:00 pm with scope for further expansion based on demand.</p>
                    <p>We have been officially moved from Malayalam Mission Australian chapter in 2023 and ‘School of Malayalam Language Inc.’ We started our own
                    curriculum to overcome the difficult problem of introducing our mother tongue to the children of the migrant community.</p>
                    <p>We have been able to prepare well-structured handbooks for each class as a result of the continuous efforts of the teachers and the school management
                    committee. It is our hope that this handbook will be the first step in our efforts to fully achieve our goal of a self-contained syllabus. We cordially request the
                    cooperation of all Malayalees who love the language in the journey to achieve that great goal.</p>
                </div>
                <div className='students'>test</div>

                <div className='inner_body'><h2>Our Mission</h2>
                    <div className='sets-icon'><img src={Mission} alt="test" />
                        <p>To embracing the Malayalam language with well-structured curriculum and teaching in classroom environment to allow next generation of Australian
                        Malayalees to connect with their rich cultural heritage.</p></div>
                    
                
                </div>
                <Team />
            </div>
            
        </div>

        <Footer />
        </>
    )
}

export default About
