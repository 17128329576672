import React from 'react'
import './Footer.css';
import './Media-styles.css';
import FB from './img/fb.webp';

import Mail from './img/mail.webp';

import Ft_Logo from './img/Banners/smp-logo.png';



function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-col1'><a href="/"><img src={Ft_Logo} alt="SML Perth" /></a><span className="ft-info">School of Malayalam Languages - Perth</span></div>
      <div className='footer-col2'><h3 className="ft-col-tit">More information</h3><ul>
        <li className='ft-col-list pool-range-desk'><a href="./sml">Home</a></li>
        <li className='ft-col-list pool-range-desk'><a href='./about'>About SML</a></li>
        <li className='ft-col-list pool-range-desk'><a href='./sml'>Curriculum</a></li>
        <li className='ft-col-list pool-range-desk'><a href='./sml'>Enrolment</a></li>
        <li className='ft-col-list pool-range-desk'><a href='./sml'>Gallery</a></li>
        <li className='ft-col-list pool-range-mob'><a href='./sml'>News</a></li>
      </ul></div>
 
      <div className='footer-col3'><h3 className="ft-col-tit">Downloads</h3><ul>
       
        <li className='ft-col-list'><a href="https://docs.google.com/forms/d/e/1FAIpQLSd1qXQDMDsN4uQkmQ6TToJLoRQqbY274J-_lnCFcWaEK0rtDg/viewform?fbclid=IwZXh0bgNhZW0BMQABHaIspbRFn35rsSkfcaaBha2QvRAWNEt9p-Ltg7HbQQTnP6We0UAnVFxM3A_aem_AXP-g6yuAV8Pi8M4bKXVD6TaIy8uOnxinjPjVb0E815I2AYQcccWUiBzUz5ChaRmDCo" target='_blank'>Enrolment Form</a></li>
       


      </ul></div>
      <div className='footer-col4'><h3 className="ft-col-tit">Follow us</h3>
        <ul>
          <li className='social'><a href="./sml"><img src={FB} alt="test" /></a></li>

          <li className='social'><a href="./sml"><img src={Mail} alt="test" /></a></li>

        </ul>
      </div>
    </div>
  )
}

export default Footer;