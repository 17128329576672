import React, { useState } from 'react';

import '../components/Models/Styles/Slider.css';

function GallerySlider() {



  const imgs = [

    { id: 0, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-001.webp" },
    { id: 1, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-002.webp" },
    { id: 2, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-003.webp" },
    { id: 3, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-004.webp" },
    { id: 4, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-005.webp" },
    { id: 5, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-006.webp" },
    { id: 6, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-007.webp" },
    { id: 7, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-008.webp" },
    { id: 8, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-009.webp" },
    { id: 9, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-010.webp" },
    { id: 10, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-011.webp" },
    { id: 11, value: "http://www.schoolofmalayalamperth.com.au/Gallery/sml-012.webp" },

  ]

  const [wordData, setWordData] = useState(imgs[0])
  const [val, setVal] = useState(0)
  const handleClick = (index) => {
    console.log(index)

    setVal(index)

    const wordSlider = imgs[index];

    setWordData(wordSlider)

  }





  return (

    <div className="main">



      <img src={wordData.value} alt="SML - Perth" height="auto" width="90%" />



      <div className='flex_row'>

        {imgs.map((data, i) =>

          <div className="thumbnail" key={i} >

            <img className={wordData.id === i ? "clicked" : ""} alt="SML - Perth" src={data.value} onClick={() => handleClick(i)} height="50" />

          </div>

        )}

        <div class="val">{val}</div>

      </div>

    </div>

  );

}



export default GallerySlider;