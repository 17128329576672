import React from 'react'
import Admin1 from '../img/Team/Admin-Team/K P Shibu.webp';
import Admin2 from '../img/Team/Admin-Team/Sunil Kumar.webp';
import Admin3 from '../img/Team/Admin-Team/Rishi Philip Oommen.webp';
import Admin4 from '../img/Team/Admin-Team/Mohammed Ali Sheriff.webp';
import Admin5 from '../img/Team/Admin-Team/Joshy P Andrews.webp';
import Admin6 from '../img/Team/Admin-Team/Rajesh Raman Pillai.webp';




function MediumPools() {
  return (
    <>
   <div className='range-title'><h3>Admin Team</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><img src={Admin1} alt="SML Staff" /><div class="blue-bar">K.P. Shibu</div></div>
    <div className='pro-col2'><img src={Admin2} alt="OasiSML Staffs" /><div class="blue-bar">Sunil Kumar</div></div>
    <div className='pro-col3'><img src={Admin3} alt="SML Staff" /><div class="blue-bar">Rishi Philip Oommen</div></div>   
    <div className='pro-col4'><img src={Admin4} alt="SML Staff" /><div class="blue-bar">Mohammed Ali Sheriff</div></div>  
    <div className='pro-col5'><img src={Admin5} alt="SML Staff" /><div class="blue-bar">Joshy P Andrews</div></div> 
    <div className='pro-col6'><img src={Admin6} alt="SML Staff" /><div class="blue-bar">Rajesh Raman Pillai</div></div> 
   
  </div>

  </>
 
  )
}

export default MediumPools