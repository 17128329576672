import React from 'react'

function HomeBanner() {
  return (
    <>
    <div className='main-banner'>test</div>
    <div className='main-banner-mob'>test</div>
    </>
  )
}

export default HomeBanner
