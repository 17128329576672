import React from 'react'
import './Header.css';
import './Media-styles.css';
import Navigation from './Navigation';
import Logo from './img/Banners/smp-logo.png'


function Header() {
  return (
   
    <div className='header-container'>
    <div className='header-logo-navbar'>
      <div className='header-left'><a href="/sml/"><img src={Logo} alt="SML Perth" /></a></div>
   
      <div className='header-right'><Navigation /></div>
      </div>
    </div>
  )
}

export default Header


