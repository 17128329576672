import React from 'react'
import Tech1 from '../img/Team/Teaching-Staffs/Raji Prasad.webp';
import Tech2 from '../img/Team/Teaching-Staffs/Bhagyalakshmi Remani.webp';
import Tech3 from '../img/Team/Teaching-Staffs/Shaju K Francis.webp';
import Tech4 from '../img/Team/Teaching-Staffs/Marydas Paulose.webp';
import Tech5 from '../img/Team/Teaching-Staffs/Priyanka Sara Ninan.webp';
import Tech6 from '../img/Team/Teaching-Staffs/Bindhu P Rajan.webp';
import Tech7 from '../img/Team/Teaching-Staffs/Thara Shibu.webp';
import Tech8 from '../img/Team/Teaching-Staffs/Sindhu Muttathil.webp';
import Tech9 from '../img/Team/Teaching-Staffs/Vineetha Praveen Kumar.webp';
import Tech10 from '../img/Team/Teaching-Staffs/Jameela Sheriff.webp';
import Tech11 from '../img/Team/Teaching-Staffs/Ranjini Sachithran.webp';
import Tech12 from '../img/Team/Teaching-Staffs/Rajesh Kochappan.webp';
import Tech13 from '../img/Team/Teaching-Staffs/Jessil Philip.webp';
import Tech14 from '../img/Team/Teaching-Staffs/Divya Baby.webp';
import Tech15 from '../img/Team/Teaching-Staffs/Sruthi Raj.webp';
import Tech16 from '../img/Team/Teaching-Staffs/Roy-Thomas.jpg';
import Tech17 from '../img/Team/Teaching-Staffs/Revothy Thazhathuveedu.webp';
import Tech18 from '../img/Team/Teaching-Staffs/Rashmi Chandran.webp';
import Tech19 from '../img/Team/Teaching-Staffs/Soji James.webp';
import Tech20 from '../img/Team/Teaching-Staffs/Rinu Ann.webp';
import Tech21 from '../img/Team/Teaching-Staffs/Arya Abhishek.webp';
import Tech22 from '../img/Team/Teaching-Staffs/Sreepriya Satheesh.webp';
import Tech23 from '../img/Team/Teaching-Staffs/Vipin Kumar.webp';
import Tech24 from '../img/Team/Teaching-Staffs/Sandhya Subin.webp';
import Tech25 from '../img/Team/Teaching-Staffs/Anila-Stephen.jpg';
import Tech26 from '../img/Team/Teaching-Staffs/Sukumaran.jpg';




function MediumPools() {
  return (
    <>
   <div className='range-title'><h3>Teaching Staffs</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><img src={Tech1} alt="SML Staff" /><div class="blue-bar">Raji Prasad</div></div>
    <div className='pro-col2'><img src={Tech2} alt="OasiSML Staffs" /><div class="blue-bar">Bhagyalakshmi Remani</div></div>
    <div className='pro-col3'><img src={Tech3} alt="SML Staff" /><div class="blue-bar">Shaju K Francis</div></div>   
    <div className='pro-col4'><img src={Tech4} alt="SML Staff" /><div class="blue-bar">Marydas Paulose</div></div>  
    <div className='pro-col5'><img src={Tech5} alt="SML Staff" /><div class="blue-bar">Priyanka Sara Ninan</div></div> 
    <div className='pro-col6'><img src={Tech6} alt="SML Staff" /><div class="blue-bar">Bindhu P Rajan</div></div> 
    <div className='pro-col7'><img src={Tech7} alt="SML Staff" /><div class="blue-bar">Thara Shibu</div></div> 
    <div className='pro-col8'><img src={Tech8} alt="SML Staff" /><div class="blue-bar">Sindhu Muttathil</div></div> 
    <div className='pro-col9'><img src={Tech9} alt="SML Staff" /><div class="blue-bar">Vineetha Praveen Kumar</div></div> 
    <div className='pro-col10'><img src={Tech10} alt="SML Staff" /><div class="blue-bar">Jameela M Sheriff</div></div>
    <div className='pro-col11'><img src={Tech11} alt="SML Staff" /><div class="blue-bar">Ranjini Sachithran</div></div> 
    <div className='pro-col12'><img src={Tech12} alt="SML Staff" /><div class="blue-bar">Rajesh Kochappan</div></div> 
    <div className='pro-col12'><img src={Tech13} alt="SML Staff" /><div class="blue-bar">Jessil Philip</div></div> 
    <div className='pro-col14'><img src={Tech14} alt="SML Staff" /><div class="blue-bar">Divya Baby</div></div> 
    <div className='pro-col15'><img src={Tech15} alt="SML Staff" /><div class="blue-bar">Sruthi Raj</div></div> 
    <div className='pro-col16'><img src={Tech16} alt="SML Staff" /><div class="blue-bar">Roy Thomas</div></div> 
    <div className='pro-col17'><img src={Tech17} alt="SML Staff" /><div class="blue-bar">Revothy K Thazhathuveedu</div></div> 
    <div className='pro-col18'><img src={Tech18} alt="SML Staff" /><div class="blue-bar">Rashmi K Chandran</div></div> 
    <div className='pro-col19'><img src={Tech19} alt="SML Staff" /><div class="blue-bar">Soji James</div></div> 
    <div className='pro-col20'><img src={Tech20} alt="SML Staff" /><div class="blue-bar">Rinu Ann</div></div> 
    <div className='pro-col21'><img src={Tech21} alt="SML Staff" /><div class="blue-bar">Arya Abhishek</div></div> 
    <div className='pro-col22'><img src={Tech22} alt="SML Staff" /><div class="blue-bar">Sreepriya Satheesh</div></div> 
    <div className='pro-col23'><img src={Tech23} alt="SML Staff" /><div class="blue-bar">Vipin Kumar Murikulathil</div></div> 
    <div className='pro-col24'><img src={Tech24} alt="SML Staff" /><div class="blue-bar">Sandhya Subin</div></div> 
    <div className='pro-col25'><img src={Tech25} alt="SML Staff" /><div class="blue-bar">Anila Stephen</div></div> 
    <div className='pro-col26'><img src={Tech26} alt="SML Staff" /><div class="blue-bar">Sukumaran</div></div> 

    
  </div>

  </>
 
  )
}

export default MediumPools