import React from 'react'
import Header from './Header';
import Footer from './Footer';
import './Home.css';
import './Media-styles.css';
/*import Bar from './img/ATCAN These Numbers JAN2024_banner.webp';
import MobBar from './img/ATCAN These Numbers JAN2024_mobileB.webp';
import BestSellers from './Best-Sellers';*/
import Welcome from './Welcome';
import HomeBanner from './Home-Banner';

function Home() {
  return (
    <>
      <Header />

      <div className='home-container'>
        <HomeBanner/>
        <Welcome />
        
        <div className='inner_container'>
           
            <div className='inner-body-container'>
                <div className='inner_body'><h1>About Malayalam</h1>
                    <h3>മലയാളം </h3>
                    <p>Malayalam (മലയാളം)is a Dravidian language spoken in the Indian state of Kerala and the union territories of Lakshadweep and
                    Puducherry . It is one of 22 scheduled languages of India. Malayalam was designated a &quot;Classical Language of India&quot; in 2013.</p>
                    <p>The word Malayalam originated from the words mala, meaning &#39;mountain&#39;, and alam, meaning &#39;region&#39; or &#39;-ship&#39; (as in &quot;township&quot;); Malayalam thus
                    translates directly as &#39;the mountain region&#39;.</p>
                    <p>The earliest extant literary works in the regional language of present-day Kerala probably date back to as early as the 12th century. At that time the
                    language was differentiated by the name Kerala Bhasha. The distinctive &#39;Malayalam&#39; named identity of this language appears to have come into existence
                    only around the 16th century when it was known as &quot;Malayayma&quot; or &quot;Malayanma&quot;; the words were also used to refer to the script and the region.</p>
                </div>
                <div className='staff'>test</div>
                <div className='inner_body'><h1>Why SML</h1>
                
                <p>Learning Malayalam not only preserves cultural heritage but also enhances communication, community bonds, and understanding of the
                language’s rich history. Whether in Australia, or elsewhere, keeping Malayalam alive is a testament to our roots and shared experiences.</p>
                <p>SML(formerly Malayalam Community Language School) has inaugurated on 23 rd Sep-2021 in Piara Waters, Perth. The school is fulfillment of a long-time
                dream of West Australians of Malayalee origin who wanted an institution to pass on their mother tongue to the next generation.</p>
                <p>We have a team of volunteer professionals from various backgrounds oversee management and over 30 people have volunteered as teachers. We started
                our own curriculum to overcome the difficult problem of introducing our mother tongue to the children of the migrant community. We have been able to
                prepare well-structured handbooks for each class as a result of the continuous efforts of the teachers and the school management committee.</p>
            </div>
            
            </div>
        </div>
        
        
      </div>
      <Footer />
    </>
  )
}

export default Home
