import React from 'react'



import './Style.css';

import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';

import GallerySlider from './Gallery-Images';
import GallerySliderMob from './Gallery-Images-Mob';
import AboutBanner from './img/Banners/abount-banner-desk.jpg';
import AboutBannerMob from './img/Banners/abount-banner-mob.jpg';



//import GallerySlider from '.././components/Models/Amalfi/Slider';

function Gallery() {

    return (



        <>

            <Header />



            <div className='inner_container'>
                <div className='header-banner'><img src={AboutBanner} alt="test" /></div>
                <div className='header-banner-mob'><img src={AboutBannerMob} alt="test" /></div>

                <div className='inner-body-container'>

                    <div className='inner_body'><h1>Gallery</h1>
                        <h3>School of Malayalam - Perth </h3>



                        <p>History: In 2021 a group of passionate Malayalees came together to form &quot;Malayalam Mission Australia Ltd&quot; to fulfill this aspiration. They liaised with other
                            community groups and the public in Perth. An important development was the tie-up with Malayalam Mission Kerala which provides a certificate course to
                            teach Malayalam. Thus, a school was formed with the name &quot;Malayalam Community Language School, Perth&#39;&#39;.</p>




                        <div className='Gallery-grid'><GallerySlider /></div>

                        <div className='Gallery-grid-mob'><GallerySliderMob /></div>



                    </div>



                </div>



            </div>



            <Footer />

        </>

    )

}



export default Gallery