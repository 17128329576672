import React from 'react'


import './Style.css';
import './Staff.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import AboutBanner from './img/Banners/abount-banner-desk.jpg';
import AboutBannerMob from './img/Banners/abount-banner-mob.jpg';
import Curriculum1 from '../components/Curriculum/Kindergarten.jpg';
import Curriculum2 from '../components/Curriculum/Pre-Primary.jpg';
import Curriculum3 from '../components/Curriculum/Year-1.jpg';
import Curriculum4 from '../components/Curriculum/Year-2.jpg';
import Curriculum5 from '../components/Curriculum/Year-3.jpg';
import Curriculum6 from '../components/Curriculum/Year-4.jpg';
import Curriculum7 from '../components/Curriculum/Year-5.jpg';
import Curriculum8 from '../components/Curriculum/Year-6.jpg';
import Curriculum9 from '../components/Curriculum/Year-7.jpg';

import './Curriculum.css';

import ModalPopup1 from './Curriculum-Books/Kindergarden';
import ModalPopup2 from './Curriculum-Books/Preprimary';
import ModalPopup3 from './Curriculum-Books/Year1';
import ModalPopup4 from './Curriculum-Books/Year2';
import ModalPopup5 from './Curriculum-Books/Year3';
import ModalPopup6 from './Curriculum-Books/Year4';
import ModalPopup7 from './Curriculum-Books/Year5';
import ModalPopup8 from './Curriculum-Books/Year6';
import ModalPopup9 from './Curriculum-Books/Year7';

function TwoColumn() {

  return (
<>

<Header />
        
<div className='inner_container'>
    <div className='header-banner'><img src={AboutBanner} alt="test" /></div>
    <div className='header-banner-mob'><img src={AboutBannerMob} alt="test" /></div>
    <div className='inner-body-container'>
        <div className='inner_body'><h1>Curriculum</h1>
            <h3> </h3>
            <p>Perth&#39;s Malayalam Language School has taken yet another important step. We started our own curriculum to overcome the difficult problem of
            introducing our mother tongue to the children of the migrant community. We are very proud to inform everyone that we have been able to prepare
            handbooks for each class as a result of the continuous efforts of the teachers and the school management committee.</p>
            <p>സ്വന്തമായ സിലബസിലേക്ക് എന്ന ലക്‌ഷ്യം പരിപൂർണ്ണമായും കൈവരിക്കുവാനുള്ള നമ്മുടെ പരിശ്രമത്തിന്റെ
            ആദ്യപടിയായി ഈ കൈപ്പുസ്തകം മാറുമെന്നാണ് ഞങ്ങളുടെ പ്രതീക്ഷ. ഈ അദ്ധ്യയനവർഷത്തിന്റെ അവസാനത്തോടെ ആ
            വലിയ ലക്‌ഷ്യം കയ്യെത്തിപ്പിടിക്കുവാനുള്ള പ്രയാണത്തിൽ ഭാഷയെ സ്നേഹിക്കുന്ന മുഴുവൻ മലയാളികളുടെയും സഹായ
            സഹകരണങ്ങൾ ഉണ്ടാകണമെന്ന് സ്നേഹപൂർവ്വം അഭ്യർത്ഥിക്കുന്നു.</p>
            <div className='students'>test</div>
            <h3>Class Books</h3>
            <p>പെർത്തിലെ മലയാള ഭാഷാസ്‌കൂൾ നമ്മുടെ കുട്ടികളുടെ സാമൂഹ്യ സാഹചര്യങ്ങൾ മനസ്സിലാക്കി, അവരുടെ മാതൃഭാഷാ
            പഠനം സുഗമമാക്കുന്നതായി കഴിഞ്ഞ രണ്ടുവർഷമായി കുട്ടികളുമായുള്ള നിരന്തര സമ്പർക്കവും, സാമീപ്യവും
            ഉൾക്കൊണ്ടുകൊണ്ട് അധ്യാപകർ തയ്യാറാക്കിയ പുസ്തകങ്ങളുടെ പുറംചട്ടകളാണ് ഇവിടെച്ചേർക്കുന്നത്.</p>
            <div className='TwoColumn_Container'>

            <div className='TwoColumn-left'><img src={Curriculum1} alt="banner" /><ModalPopup1/></div>
        
            <div className='TwoColumn-left'><img src={Curriculum2} alt="banner" /><ModalPopup2/></div>
            <div className='TwoColumn-left'><img src={Curriculum3} alt="banner" /><ModalPopup3/></div>
        
             
        
            </div>
            <div className='TwoColumn_Container'>

            <div className='TwoColumn-left'><img src={Curriculum4} alt="banner" /><ModalPopup4/></div>
        
            <div className='TwoColumn-left'><img src={Curriculum5} alt="banner" /><ModalPopup5/></div>
            <div className='TwoColumn-left'><img src={Curriculum6} alt="banner" /><ModalPopup6/></div>
        
             
        
            </div>
            <div className='TwoColumn_Container'>

            <div className='TwoColumn-left'><img src={Curriculum7} alt="banner" /><ModalPopup7/></div>
        
            <div className='TwoColumn-left'><img src={Curriculum8} alt="banner" /><ModalPopup8/></div>
            <div className='TwoColumn-left'><img src={Curriculum9} alt="banner" /><ModalPopup9/></div>
        
             
        
            </div>
           
        </div>
       
       
        
    </div>
    
</div>

<Footer />



 
</>
  )

}

 

export default TwoColumn