import React from 'react'
import { useState } from 'react'
import './SML-Team.css';
import Management from './SMLTeam/Management';
import Technical from './SMLTeam/Technical';
import Admin from './SMLTeam/Admin';


function Pooltabs() {
    const [toggle, setToggle] = useState(1)
    function updateToggle(id) {
        setToggle(id)
    }
    return (
        <div className='tab'>
            <ul className='d-flex'>
                <li className='flex-fill' onClick={() => updateToggle(1)}>Management Team</li>
                <li className='flex-fill' onClick={() => updateToggle(2)}>Teaching Staffs</li>
                <li className='flex-fill' onClick={() => updateToggle(3)}>Admin Team</li>
                
            </ul>
            <div className={toggle === 1 ? "show-content" : "content"}>
                <Management />
            </div>
            <div className={toggle === 2 ? "show-content" : "content"}>
                <Technical />
            </div>
            <div className={toggle === 3 ? "show-content" : "content"}>
                <Admin />
            </div>
           

        </div>
    )
}

export default Pooltabs
