
import './App.css';
import './components/Font_style.css';

import './components/Media-styles.css';

import Home from './components/Home';
import About from './components/About';
import Team from './components/Office-Staffs';
import Gallery from './components/Gallery';
import Curriculum from './components/Curriculum';
import Enrolment from './components/Enrolment';






import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/sml/",
    element: <Home />,
  },

  {
    path: "/sml/About",
    element: <About />,
  },

  {
    path: "/sml/Office-Staffs",
    element: <Team />,
  },

  {

    path: "/sml/Gallery",

    element: <Gallery />,

  },

  {

    path: "/sml/Curriculum",

    element: <Curriculum />,

  },
  
{
  
  path: "/sml/Enrolment",

  element: <Enrolment />,

}




  

 

 
]);

function App() {
  return (
    <div className="App">

   <RouterProvider router={router} />
 
    </div>
  );
}

export default App;
