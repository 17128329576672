import React from 'react'
import './Style.css';
import './Staff.css';
import './Media-styles.css';
import Header from './Header';
import Footer from './Footer';
import AboutBanner from './img/Banners/abount-banner-desk.jpg';
import AboutBannerMob from './img/Banners/abount-banner-mob.jpg';




function Enrolment() {
    return (
        <>
            <Header />
        
        <div className='inner_container'>
            <div className='header-banner'><img src={AboutBanner} alt="test" /></div>
            <div className='header-banner-mob'><img src={AboutBannerMob} alt="test" /></div>
            <div className='inner-body-container'>
                <div className='inner_body'><h1>Enrolment</h1>
                    <h3>School of Malayalam - Perth </h3>
                    
                    <p>more....</p>

                    
                </div>
                <div className='students'>test</div>
<div className='sml-btn'><a href="https://docs.google.com/forms/d/e/1FAIpQLSd1qXQDMDsN4uQkmQ6TToJLoRQqbY274J-_lnCFcWaEK0rtDg/viewform?fbclid=IwZXh0bgNhZW0BMQABHaIspbRFn35rsSkfcaaBha2QvRAWNEt9p-Ltg7HbQQTnP6We0UAnVFxM3A_aem_AXP-g6yuAV8Pi8M4bKXVD6TaIy8uOnxinjPjVb0E815I2AYQcccWUiBzUz5ChaRmDCo" target="_blank">Download Form</a></div>
             
            </div>
            
        </div>

        <Footer />
        </>
    )
}

export default Enrolment
